// import React, { Component } from 'react'
// import { observable, runInAction, makeObservable } from 'mobx'
// import { observer } from 'mobx-react'
// import { Redirect } from 'react-router-dom'

// import { APP_USERS_SIGN_IN_PATH } from 'constants/routes.constants'
// import { notify } from 'libs/notify'

// import { getSearchParams } from 'utils/queryString.utils'
// import { submitConfirmation } from '../../api/session.api'

// class ConfirmationHandlerStore {
//   @observable confirmationFailed = false
//   @observable confirmationSucceeded = false

//   constructor() {
//     makeObservable(this)
//   }

//   async makeConfirmationRequest() {
//     const { confirmation_token: confirmationToken } = getSearchParams()
//     const resp = await submitConfirmation({ confirmationToken }, { skipErrorNotific: true })
//     const { success, error } = resp || {}

//     if (success) {
//       runInAction(() => (this.confirmationSucceeded = true))
//       notify('success', I18n.t('react.app.form.email_confirmed'))
//     } else {
//       runInAction(() => (this.confirmationFailed = true))
//       notify('error', error || I18n.t('react.app.form.email_unconfirmed'))
//     }
//   }
// }

// @observer
// class ConfirmationHandler extends Component {
//   store = new ConfirmationHandlerStore()

//   componentDidMount() {
//     this.store.makeConfirmationRequest()
//   }

//   render() {
//     const { confirmationFailed, confirmationSucceeded } = this.store

//     if (confirmationFailed || confirmationSucceeded) {
//       return <Redirect to={APP_USERS_SIGN_IN_PATH} />
//     }

//     return <Spinner />
//   }
// }

// export default ConfirmationHandler

import React from 'react'

const ConfirmationHandler = () => <div>ConfirmationHandler</div>

export default ConfirmationHandler
